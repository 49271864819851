import React from 'react';
import Case from '../../../sections/Case';
import {useEnCaseData} from '../../../shared/Hooks/Case/useEnCaseData';
import {useEnHeaderData} from '../../../shared/Hooks/Headers/useEnHeaderData';

const CaseViscoplast = () => {
  const data = {
    seo: {
      title: 'Futuro and Viscoplast – mini-campaigns.',
    },
    cases: useEnCaseData().viscoplast,
    header: useEnHeaderData().futuro,
  };

  return (
    <Case data={data}/>
  );
};

export default CaseViscoplast;
